import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export const toastMixin = {
  components: {
    ToastificationContent
  },
  methods: {
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'AlertTriangleIcon',
          text,
          variant
        },
      })
    },
    showError(message = 'Something went wrong!') {
      this.showToast('error', 'AlertTriangleIcon', 'Error', message)
    },
    showSuccess(title, message) {
      this.showToast('success', 'CheckIcon', title , message)
    }
  },

}
